var _ = require('underscore');
var $ = require('jquery');

require("jquery-ui")

window.mnumiJQuery = $;

if (!window.jQuery) {
    window.jQuery = $;
}

const readyEvent = new CustomEvent("mnumi:ready", {
    detail: {
        jquery: $,
        underscore: _
    }
});

window.dispatchEvent(readyEvent);
