var $ = require('jquery');

function resetForm(id) {
    $('#'+id).each(function() {
        this.reset();
    });
}

var match = function (a, i, m) {
    return RegExp(m[3]).test(a.textContent || a.innerText || $(a).text() || "");
};

var match_ignore_case = function (a, i, m) {
    return RegExp(m[3], 'i').test(a.textContent || a.innerText || $(a).text() || "");
};

function checkSizeChoice() {
    var selectVal = $("#mns select.with_special_choice").val();
    var customizableVal = $("#mns select.with_special_choice option:containsmatch('^- (.)* -$')").val();

    var obj = $('#mns .order-form input.size').parent();
    if (selectVal == customizableVal) {
        $(obj).show();
    }
    else {
        $(obj).hide();
    }
}

$(document).ready(function($) {
    /* auto height for product li */
    var maxHeight = 0;
    $(".products-grid .item").each(function() {
        if (maxHeight < $(this).height()) {
            maxHeight = $(this).height();
        }
    });

    $(".products-grid .item").height(maxHeight);

    $.extend($.expr[':'], {
        containsmatch: match,
        containsmatchi: match_ignore_case
    });

    var fixed = $('.fixed');
    $(fixed).each(function() {
        var pos = $(this).offset();
        var $this = $(this);
        var next = $($this).next();
        var nextMarginTop = parseInt($(next).css('marginTop'));
        var height = $($this).outerHeight(true);

        $(window).scroll(function() {
            if ($(this).scrollTop() > pos.top) {
                $($this).css('position', 'fixed');
                $(next).css('marginTop', nextMarginTop + height);
            }
            else {
                $($this).css('position', 'static');
                $(next).css('marginTop', nextMarginTop);
            }
        });
    });

    if ($("#mns select.with_special_choice")) {
        $('#mns select.with_special_choice').change(function() {
            checkSizeChoice($(this));
        });
        checkSizeChoice();
    }
});


